import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import logo from './assets/logo_antoo.png';
import downArrow from './assets/fleche-bas.png';
import styles from './Subscribe.module.css';

function App() {

  const [email, setEmail] = useState('');
  const [popup, setPopup] = useState({ visible: false, message: '', success: false });
  const [stars, setStars] = useState([]);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setPopup({ visible: true, message: 'Veuillez entrer une adresse e-mail valide.', success: false });
      setTimeout(() => setPopup({ visible: false, message: '', success: false }), 3000);
      return;
    }

    const response = await fetch('https://antoo.net/emails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    });

    if (response.ok) {
      setPopup({ visible: true, message: 'Inscription réussie !', success: true });
    } else {
      setPopup({ visible: true, message: 'Erreur lors de l\'inscription.', success: false });
    }

    setTimeout(() => {
      setPopup((prevPopup) => ({ ...prevPopup, visible: false }));
      setTimeout(() => setPopup({ visible: false, message: '', success: false }), 500); // Délai pour le fondu
    }, 3000);
  };

  const generateStars = (numStars) => {
    const stars = [];
    for (let i = 0; i < numStars; i++) {
      const top = Math.random() * 100 + '%';
      const left = Math.random() * 100 + '%';
      const animationDelay = Math.random() * 5 + 's';
      const animationDuration = Math.random() * 2 + 5 + 's';
      const translateY = (Math.random() * 100 + 100) + 'vh';
      const isBlinking = Math.random() > 0.5;
      stars.push(
        <div
          key={i}
          className={`${styles.star} ${isBlinking ? styles['star-blink'] : styles['star-move']}`}
          style={{
            top,
            left,
            animationDelay,
            animationDuration,
            '--translate-y': translateY,
          }}
        ></div>
      );
    }
    return stars;
  };

  useEffect(() => {
    setStars(generateStars(100));
  }, []);

  return (
    <div className={styles.App}>
      <Helmet>
      <title>Antoo - Find the ideal partner for your projects</title>
      <meta name="description" content="Avec Antoo, trouvez le partenaire idéal pour transformer vos idées en succès." />
      <meta name="keywords" content="Matching entrepreneurial, Trouver un associé startup, Plateforme pour entrepreneurs, Réseau entrepreneurial, Partenariat startup, Application pour entrepreneurs, Communauté d’entrepreneurs, Association d’entrepreneurs, Création de startup, Collaborateur startup, Networking startup, Recherche co-fondateur, Collaboration entrepreneuriale, Mentor startup, Équipe fondatrice, Levée de fonds startup, Écosystème entrepreneurial, Événements pour entrepreneurs, Gestion de projet startup, Entrepreneuriat digital, Comment trouver un associé pour ma startup, Plateforme de mise en relation pour entrepreneurs, Créer une équipe startup performante, Outils pour startups en phase de lancement, Réseau d’entrepreneurs pour jeunes créateurs, App de matching pour entrepreneurs, Application pour trouver des partenaires business, Innovation entrepreneuriale, Algorithme de matching startup, Solutions innovantes pour startups, Nouveaux modèles d’entrepreneuriat, Startups disruptives, Entrepreneuriat en France, Startup Paris, Écosystème startup européen" />
      <meta property="og:title" content="Antoo - Trouvez le partenaire idéal pour vos projets" />
      <meta property="og:description" content="Avec Antoo, trouvez le partenaire idéal pour transformer vos idées en succès." />
      <meta property="og:image" content={logo} />
      </Helmet>
      <header className={styles.Header} id="top">
      </header>

      <main className={styles.Body}>
        <div className={styles.stars}>
          {stars}
        </div>
        <div className={styles.Title}>
          <h1>Welcome to Antoo</h1>
          <h3>Sign up now and be the first to know about the app's release!</h3>
          <input
            type="email"
            value={email}
            onChange={handleInputChange}
            placeholder="Your email address"
            className={styles['email-input']}
          />
          <button onClick={handleSubmit} className={styles['submit-button']}>Join the adventure</button>
        </div>
        {popup.visible && (
          <div className={`${styles.popup} ${popup.success ? styles.success : styles.error} ${!popup.visible ? styles.hidden : ''}`}>
            {popup.message}
          </div>
        )}
      </main>

    </div>
  );
}

export default App;