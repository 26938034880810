import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, Link } from 'react-router-dom';
import logo from './assets/logo+name_antoo.png';
import styles from './Dashboard.module.css';

function App() {
  const navigate = useNavigate();

  const [users, setUsers] = useState(0);
  const [growth, setGrowth] = useState(0);
  const [incidents, setIncidents] = useState(0);
  const [totalSwipes, setTotalSwipes] = useState(0);
  const [studentsPlan, setStudentsPlan] = useState(0);
  const [normalPlan, setNormalPlan] = useState(0);
  const [freePlan, setFreePlan] = useState(0);
  const [totalMatchs, setTotalMatchs] = useState(0);
  const [recentUsers, setRecentUsers] = useState(0);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch('http://localhost:4000/admin/dashboard-data', {
          headers: {
            'Authorization': `${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        setUsers(data.userCount);
        setFreePlan(data.freePlanCount);
        setNormalPlan(data.normalPlanCount);
        setStudentsPlan(data.studentPlanCount);
        setTotalSwipes(data.totalSwipes);
        setTotalMatchs(data.totalMatchs);
      } catch (error) {
        console.error('Erreur lors de la récupération du nombre d\'utilisateurs:', error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className={styles.App}>
      <Helmet>
        <title>Antoo - Find the ideal partner for your projects</title>
        <meta name="description" content="Avec Antoo, trouvez le partenaire idéal pour transformer vos idées en succès." />
        <meta name="keywords" content="Matching entrepreneurial, Trouver un associé startup, Plateforme pour entrepreneurs, Réseau entrepreneurial, Partenariat startup, Application pour entrepreneurs, Communauté d’entrepreneurs, Association d’entrepreneurs, Création de startup, Collaborateur startup, Networking startup, Recherche co-fondateur, Collaboration entrepreneuriale, Mentor startup, Équipe fondatrice, Levée de fonds startup, Écosystème entrepreneurial, Événements pour entrepreneurs, Gestion de projet startup, Entrepreneuriat digital, Comment trouver un associé pour ma startup, Plateforme de mise en relation pour entrepreneurs, Créer une équipe startup performante, Outils pour startups en phase de lancement, Réseau d’entrepreneurs pour jeunes créateurs, App de matching pour entrepreneurs, Application pour trouver des partenaires business, Innovation entrepreneuriale, Algorithme de matching startup, Solutions innovantes pour startups, Nouveaux modèles d’entrepreneuriat, Startups disruptives, Entrepreneuriat en France, Startup Paris, Écosystème startup européen" />
        <meta property="og:title" content="Antoo - Trouvez le partenaire idéal pour vos projets" />
        <meta property="og:description" content="Avec Antoo, trouvez le partenaire idéal pour transformer vos idées en succès." />
        <meta property="og:image" content={logo} />
      </Helmet>
      <header className={styles.Header} id="top">
      </header>

      <main className={styles.Body}>
        <div className={styles.leftMenu}>
          <img src={logo} alt="Antoo logo" className={styles.logo} />

          <section>
            <ul>
              <li><Link to="/admin/dashboard">Dashboard</Link></li>
            </ul>
          </section>
          
          <section>
            <h2>Business</h2>
            <hr />
            <ul>
              <li><Link to="/admin/statistics">Statistics</Link></li>
              <li><Link to="/admin/partners">Partners</Link></li>
              <li><Link to="/admin/discounts">Discounts</Link></li>
              <li><Link to="/admin/support">Support</Link></li>
            </ul>
          </section>

          <section>
            <h2>Customer Management</h2>
            <hr />
            <ul>
              <li><Link to="/admin/customers">Customers</Link></li>
              <li><Link to="/admin/conversations">Conversations</Link></li>
              <li><Link to="/admin/tags">Tags</Link></li>
            </ul>
          </section>
          
          <section>
            <h2>Team Management</h2>
            <hr />
            <ul>
              <li><Link to="/admin/manage/team">Manage Team</Link></li>
              <li><Link to="/admin/manage/permissions">Manage Permissions</Link></li>
            </ul>
          </section>

        </div>

        <div className={styles.body}>
          <div className={styles.topText}>
            <p>Dashboard</p>
          </div>

          <div className={styles.squaresContainer}>

            <div className={styles.squareContainer}>
              <div className={styles.square} style={{ width: '200px', height: '200px', marginRight: '10px' }}>
                <h2>Users</h2>
                <p>{users}</p>
              </div>

              <div className={styles.square} style={{ width: '200px', height: '200px', marginRight: '10px' }}>
                <h2>Growth (month)</h2>
                <p>{growth}</p>
              </div>

              <div className={styles.square} style={{ width: '200px', height: '200px', marginRight: '10px' }}>
                <h2>Incidents</h2>
                <p>{incidents}</p>
              </div>

              <div className={styles.square} style={{ width: '300px', height: '200px' }}>
                <h2>Total Swipes</h2>
                <p>{totalSwipes}</p>
              </div>
            </div>
          
            <div className={styles.squareContainer} style={{ marginTop: '10px' }}>
              <div className={styles.square} style={{ width: '200px', height: '200px', marginRight: '10px' }}>
                <h2>Student Plans</h2>
                <p>{studentsPlan}</p>
              </div>

              <div className={styles.square} style={{ width: '200px', height: '200px', marginRight: '10px' }}>
                <h2>Normal Plans</h2>
                <p>{normalPlan}</p>
              </div>

              <div className={styles.square} style={{ width: '200px', height: '200px', marginRight: '10px' }}>
                <h2>Free Plans</h2>
                <p>{freePlan}</p>
              </div>

              <div className={styles.square} style={{ width: '300px', height: '200px' }}>
                <h2>Total matchs</h2>
                <p>{totalMatchs}</p>
              </div>
            </div>

            <div className={styles.squareContainer} style={{ marginTop: '10px' }}>
              <div className={styles.square} style={{ width: '640px', height: '400px', marginRight: '10px' }}>
                <h2>Recent Users</h2>
              </div>

              <div className={styles.square} style={{ width: '300px', height: '400px' }}>
                <h2>(Unamed)</h2>
              </div>
            </div>

          </div>

        </div>
      </main>

    </div>
  );
}

export default App;